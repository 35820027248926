import React, { Component } from "react";
import socketIOClient from "socket.io-client";

//import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import throttle from 'lodash/throttle';

//import ReactSwing from 'react-swing';

//Custom components
import SimpleCard from './SimpleCard.js';
//import Tray from './Tray.js';
import NameDialog from './NameDialog.js';
import ErrorDialog from './ErrorDialog.js';

//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Container from '@material-ui/core/Container';
import FaceIcon from '@material-ui/icons/Face';

import ReactGA from 'react-ga';

//Change socket backend depending on environment.
if (process.env.NODE_ENV !== 'production') {
	//development
	//const socket = socketIOClient(process.env.NODE_ENV == 'production' ? 'https://noyoupick.one:4001' : 'https://noyoupick.one:4001');
} else {
	//production
	ReactGA.initialize('UA-160617157-1');
	ReactGA.pageview(window.location.pathname);
	//const socket = socketIOClient('https://noyoupick.one:4001');
}

const socket = socketIOClient(process.env.NODE_ENV == 'production' ? 'https://noyoupick.one:4001' : 'https://noyoupick.one:4002');

class App extends Component {

  stackEl = React.createRef();

  constructor() {
    super();

    this.state = {
      response: true,
      choicesLeft: [],
	  choice: null,
      match: null,
      match_names: null,
      onlineUsers: {},
	  stack: null,
	  errorMessage: null,
	  snackBar: null,
	  lat: 0,
	  lon: 0,
	  host: null,
	  waiting: true,
	  hasConnected: false,
	  noResults: null,
	  nameDialog: null,
	  openDialogs: true
    };

     this.handleYes = this.handleYes.bind(this);
     this.handleNo = this.handleNo.bind(this);
     this.done = this.done.bind(this);
     this.nameComplete = this.nameComplete.bind(this);
     this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);

  }

	handleYes(id) {
		if (id) {
			if (process.env.NODE_ENV == 'production') {
				ReactGA.event({
					category: 'Flow',
					action: 'Response Yes',
					label: id
				});
			}

			socket.emit('yes', id);
			var trunChoices = this.state.choicesLeft.splice(1);
			var choice = null;
			if (trunChoices.length > 0) {
				choice = trunChoices[0];
			}
			this.setState({ choicesLeft: trunChoices, choice: choice })
		}

	}

  handleNo(id) {
      if (id) {
		  if (process.env.NODE_ENV == 'production') {
			  ReactGA.event({
				  category: 'Flow',
				  action: 'Response No',
				  label: id
			  });
		  }

          socket.emit('no', id);
		  var trunChoices = this.state.choicesLeft.splice(1);
		  var choice = null;
		  if (trunChoices.length > 0) {
			  choice = trunChoices[0];
		  }
          this.setState({ choicesLeft: trunChoices, choice: choice })
      }

  }

  done(id) {
      console.log('done');
  }

  copyLinkToClipboard() {
	  navigator.clipboard.writeText('https://noyoupick.one'+window.location.pathname)
	  //TODO add some user feedback here.
  }


  componentDidMount() {

	socket.emit('joinRoom', { room: window.location.pathname } );

    socket.on("moreChoices", data => {
		var newChoices = this.state.choicesLeft.concat(data);
		console.log('incoming data; total choices', newChoices);
		this.setState({ choicesLeft: newChoices });
		if (this.state.choice == null) {
			this.setState({ choice: newChoices[0] });
		}

	});

    socket.on("match", data => {

		if (process.env.NODE_ENV == 'production') {
			ReactGA.event({
				category: 'Flow',
				action: 'Match Recieved'
			});
		}

		var matchNames = {};
		data.users.map(userID => {
			matchNames[userID] = this.state.onlineUsers[userID];
		});

		this.setState({ match: data, match_names: matchNames });

	});

    socket.on("onlineUsers", function(data){
		console.log('incoming users', data);
		this.setState({ onlineUsers: data });
	}.bind(this));

    socket.on("noResults", function(data){
		this.setState({
			noResults: (<ErrorDialog message="<p>No restaurants found for this location.</p><p>Perhaps try specifying a different address?</p>" button={ true } buttonText="Try Again" buttonLink="https://noyoupick.one" ></ErrorDialog>),
			openDialogs: false,
		});
	}.bind(this));

    socket.on("waiting", function(data){
		this.setState({ waiting: data });
	}.bind(this));

    socket.on("reconnectAll", function(data){
		window.location.href = "/";
	}.bind(this));

    socket.on("hasConnected", function(data){
		this.setState({ hasConnected: data });
	}.bind(this));

	socket.on("host", function(data) {
		this.setState({
			host: data
		});
	}.bind(this));

  }

	nameComplete(data) {
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		};

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function(pos){

				var lat = pos.coords.latitude;
				var lon = pos.coords.longitude;

				this.setState({
					lat: lat,
					lon: lon
				});

				//Got location
				if (data.lat) {
					if (data.lon) {
						lat = data.lat;
						lon = data.lon;
					}
				}

				ReactGA.set({ name: data.name });
				socket.emit('authenticate', {
					name: data.name,
					radius: data.radius,
					lat: lat,
					lon: lon,
					accuracy: pos.coords.accuracy
				});

			}.bind(this), function(err) {
				console.log('gelocation', 'error', err);
				this.setState({
					errorMessage: <ErrorDialog message="<span>Could not retrieve your device location.</span><br><span>Please try enabling location services.</span>" ></ErrorDialog>
				});
				console.log(this.state.errorMessage);
			}.bind(this), options);
		} else {
			this.setState({
				errorMessage: <ErrorDialog message="<span>Could not retrieve your device location.</span><br><span>Geolocation not supported on this device.</span>" ></ErrorDialog>
			});
		}
	}

  render() {

    this.hasChoices = false;
    if (this.state.choicesLeft.length > 0) {
        this.hasChoices = true;
    }

    this.hasMatch = false;
    if (this.state.match != null) {
        this.hasMatch = true;
        console.log('MATCH FOUND');
    }

	const theme = createMuiTheme({
	  palette: {
	    background: {
	      	default: this.props.backgroundColor
		  },
	  },
		typography: {
		  fontFamily: "Raleway, sans-serif",
		  fontWeight: '500',
		  h1: {
			  fontFamily: "Atma, cursive",
			  fontWeight: 700
		  },
		  h2: {
			  fontFamily: "Atma, cursive",
			  fontWeight: 700
		  },
		  p: {
			  fontWeight: '500',
		  }
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
		<CssBaseline />
		<div style={{ height: '100vh', margin: 0, padding: 0 }} >
			<Container maxWidth="sm" height="100%">
				{ this.state.openDialogs &&
					<NameDialog hasConnected={ this.state.hasConnected } waiting={ this.state.waiting } host={ this.state.host } nameComplete={ this.nameComplete }></NameDialog>
				}
				{ this.state.errorMessage }
				{ this.state.noResults }
				{ this.state.snackBar }
				<div>
					<Grid container spacing={1} style={{ marginTop: 10 }} direction="row" justify="space-between" alignItems="flex-start">
						<Grid item xs={12}>
							<Typography variant="h2" component="h2" style={{ fontSize: 28, fontWeight: 600, verticalAlign: 'middle' }}>
								<img style={{ width: 55, verticalAlign: 'middle' }} src="logo_200px.png"></img>
								<span style={{ marginLeft: 5, paddingTop: 25 }}>No, You Pick!</span>
							</Typography>
						</Grid>
						{this.hasMatch ?
							<Grid item xs={12}>
								<Card>
									<CardMedia
									style={{ height: '50vh' }}
									image={ this.state.match.choice.images[0] }
									title="image"
									/>
									<CardContent>
										<Typography variant="h5" style={{ fontWeight: 700 }}>
											{ this.state.match.choice.name }
										</Typography>
										<Typography style={{ fontSize: 16, fontWeight: 400 }} color="textSecondary">
											{ this.state.match.choice.vicinity }
										</Typography>
										<Typography component="p">
											<p>Looks like you'll be going to <b>{ this.state.match.choice.name }</b>!</p>
											<p>This was matched by:</p>
											<ul>
											{ Object.keys(this.state.match_names).map((id, i) =>
												<li>{ this.state.match_names[id] }</li>
											)}
											</ul>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						:
							<>
								{this.hasChoices ?

									<>

										{this.state.choice &&
											<Grid item xs={12} sm={9} md={12}>
												<SimpleCard id={ this.state.choice.id } data={ this.state.choice } lat={ this.state.lat } lon={ this.state.lon }></SimpleCard>
											</Grid>
										}

										<Grid item xs={12} sm={3} md={12}>
											<Card style={{ marginTop: 0 }}>
												<CardContent style={{ padding:0 }}>
													<center>
														<Button size="large" style={{ margin: 0, paddingLeft: 60, paddingRight: 60, paddingTop: 30, paddingBottom: 30, fontSize: 20 }} onClick={this.handleNo.bind(this, this.state.choicesLeft[0].id)} color="secondary">No</Button>
														<Button size="large" style={{ margin: 0, paddingLeft: 60, paddingRight: 60, paddingTop: 30, paddingBottom: 30, fontSize: 20 }} onClick={this.handleYes.bind(this, this.state.choicesLeft[0].id)} color="primary">Yes</Button>
													</center>
												</CardContent>
											</Card>
										</Grid>
									</>

								:
									<Grid item xs={12}>
										<Card>
											<CardContent>
												<Typography variant="h5" component="h1">
													<center>Loading...</center>
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								}
							</>
						}
						<Grid item xs={12}>
							<Card style={{marginTop:0, marginBottom: 30, textAlign: 'center'}}>
								<CardContent>
									<Typography variant="h5" component="h1">
									Who's Here
									</Typography>
									<Typography component="p">
										{Object.keys(this.state.onlineUsers).length === 0 ?
											<p>No users online</p>
										:
											<div>
											{Object.keys(this.state.onlineUsers).map(userID =>
												<p key={ 'user_'+userID } style={{ fontSize: 18, marginTop: 5, marginBottom: 5 }}><FaceIcon style={{marginBottom: -2}} />{ this.state.onlineUsers[userID] }</p>
											)}
											</div>
										}
									</Typography>
									<Typography variant="h5" component="h1" style={{ marginBottom: 5, marginTop: 20 }}>
										Invite Friends
									</Typography>
									<Typography component="p">
										<div style={{ marginBottom: 10, marginTop: 5 }}>
											<TextField
											margin="dense"
											variant="outlined"
											id="roomlink"
											label="Room Link"
											type="text"
											fullWidth
											onFocus={event => {event.target.select()}}
											value={ 'https://noyoupick.one'+window.location.pathname }
											defaultValue={ 'https://noyoupick.one'+window.location.pathname }
											InputProps={{ readOnly: true }}
											autoComplete="off"
											style={{ marginBottom: 10, fontSize: 22 }}
											/>
										</div>
										<div style={{ marginBottom: 0, marginTop: 10 }}>
											<Button variant="contained" disableElevation onClick={() => { this.copyLinkToClipboard() }}>Copy Link to Clipboard</Button>
										</div>
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<div style={{ marginBottom: 40 }}>
								<center><p style={{fontWeight: 400, fontFamily: "'Raleway', sans-serif", marginTop: 0 }}><a target="_blank" href="/about" style={{ textDecoration: 'none', color: '#212529', fontSize: '1rem' }}>Made with <span class="text-danger" style={{ color: '#dc3545' }}>❤</span> by <span style={{fontWeight: 500}}>Rai</span> in <span style={{fontWeight: 500}}>Vancouver</span></a></p></center>
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
		</MuiThemeProvider>
	);
  }
}

export default App;
