import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';

// import
import { OpenStreetMapProvider } from 'leaflet-geosearch';

// setup
const provider = new OpenStreetMapProvider();

function getSubstringIndex(str, substring, n) {
    var times = 0, index = null;

    while (times < n && index !== -1) {
        index = str.indexOf(substring, index+substring.length);
        times++;
    }

    return index;
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
		  provider.search({ query: request.input }).then(function(res) {
			  callback(res);
		  });

        //autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      style={{ width: '100%' }}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      disableOpenOnFocus
      renderInput={params => (
        <TextField
          {...params}
          label="(Blank for device location)"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
      renderOption={option => {
        //const matches = option.structured_formatting.main_text_matched_substrings;
        //const parts = parse(
        //  option.structured_formatting.main_text,
        //  matches.map(match => [match.offset, match.offset + match.length]),
        //);
		props.onAddressChange({ lat: option.raw.lat, lon: option.raw.lon });
		var i = getSubstringIndex(option.label, ',', 3);

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
                <span key={'s'} style={{ fontWeight: 400 }}>
                  { option.label.substring(0,i) }
                </span>

              <Typography variant="body2" color="textSecondary">
                { option.label.substring(i+2) }
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
