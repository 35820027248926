import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import AddressField from './AddressField.js';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class NameForm extends React.Component {

constructor(props) {
    super(props);
    this.state = {
		name: '',
		setting_radius: '1000',
		setting_address: null,
		openConfirm: false,
		open: true
	};

	this.address = null;

	this.handleChange = this.handleChange.bind(this);
	this.handleRadiusChange = this.handleRadiusChange.bind(this);
	this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);

	if (cookies.get('name')) {
		this.state.name = cookies.get('name');
	}
	if (cookies.get('radius')) {
		this.state.setting_radius = cookies.get('radius');
	}
  }

handleChange(event) {
  this.setState({name: event.target.value});
}

handleRadiusChange(event) {
  this.setState({setting_radius: event.target.value});
}

handleCloseConfirm(event) {

	this.setState({openConfirm: false});
}

handleAddressChange(value) {
  this.address_lat = value.lat;
  this.address_lon = value.lon;
}

handleSubmit(event) {
	if (this.state.name != null) {
		cookies.set('name', this.state.name, { path: '/' });
		cookies.set('radius', this.state.setting_radius, { path: '/' });
		if (this.props.host) {
			if (this.state.setting_radius) {
				this.setState({open: false});
				if (this.props.host) {
					this.setState({openConfirm: true});
				}
				this.props.nameComplete({ 'name': this.state.name, 'radius': this.state.setting_radius, 'lat': this.address_lat, 'lon': this.address_lon });
			}
		} else {
			this.setState({open: false});
			if (this.props.host) {
				this.setState({openConfirm: true});
			}
			this.props.nameComplete({ 'name': this.state.name, 'radius': this.state.setting_radius, 'lat': this.address_lat, 'lon': this.address_lon });
		}
	}
}

render() {

  return (

    <div>
	<Dialog disableBackdropClick disableEscapeKeyDown fullWidth={ true } maxWidth={ 'sm' } open={this.state.openConfirm} onClose={this.handleCloseConfirm} aria-labelledby="form-dialog-title">
		  <DialogContent>
			  <DialogContentText>
			  <Typography>
				  <center>
				  <img style={{ width: 110 }} src="logo_200px.png"></img>
				  <Typography variant="h2" component="h2" style={{ fontSize: 28, fontWeight: 600, color: 'black', marginBottom: 20 }}>
				  No, You Pick!
				  </Typography>
				  </center>
				</Typography>
			  <Typography variant="p" style={{ fontSize: 18, fontWeight: 400}}>
				  <p><b>Hey, { this.state.name }!</b> Your room has been created. Invite your friends with this link:</p>
				  <TextField
					margin="dense"
					variant="outlined"
					id="roomlink"
					label="Room Link"
					type="text"
					fullWidth
					onFocus={event => {event.target.select()}}
					value={ 'https://noyoupick.one'+window.location.pathname }
					defaultValue={ 'https://noyoupick.one'+window.location.pathname }
			        InputProps={{
			        	readOnly: true,
			        }}
					autoComplete="off"
					style={{ marginBottom: 10, fontSize: 22 }}
				  />
			  </Typography>
			  </DialogContentText>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={this.handleCloseConfirm} color="primary" type="submit">
			  Okay, got it!
			</Button>
		  </DialogActions>
	  </Dialog>
      <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={ true } maxWidth={ 'sm' } open={this.state.open} onClose={this.handleSubmit} aria-labelledby="form-dialog-title">

	  { this.props.hasConnected ?
		  <div>
			{ this.props.waiting ?
				<DialogContent>
					<DialogContentText>
					  <center><p>Waiting for the host...</p></center>
					</DialogContentText>
					<Divider />
					<DialogContentText>
					  <p>Alternatively, you can create a new room here.</p>
					  <center><Button variant="contained" color="secondary" href="https://noyoupick.one">Create New Room</Button></center>
					</DialogContentText>
				</DialogContent>
			:
			<div>
			<DialogContent>
				  <DialogContentText>
				  <Typography>
					  <center>
					  <img style={{ width: 110 }} src="logo_200px.png"></img>
					  <Typography variant="h2" component="h2" style={{ fontSize: 28, fontWeight: 600, color: 'black' }}>
					  No, You Pick!
					  </Typography>
					  </center>
					  { this.props.host ? (<p>You're the host! Get started below:</p>) : (<p>You've joined a room! Enter your name below to continue.</p>)}
					</Typography>
				  </DialogContentText>
				  <TextField
					autoFocus
					margin="dense"
					variant="outlined"
					id="name"
					label="Name"
					type="text"
					fullWidth
					value={this.state.name}
					onChange={this.handleChange}
					autoComplete="off"
					style={{ marginBottom: 10 }}
				  />
				  { this.props.host &&
					<div>
					<Divider style={{ marginTop: 20, marginBottom: 20 }}/>
					<FormLabel component="legend" style={{ marginTop: 20, marginBottom: 10 }}>Search Location</FormLabel>
					<AddressField onAddressChange={ this.handleAddressChange } ></AddressField>
					<FormLabel component="legend" style={{ marginTop: 20, marginBottom: 10 }}>Radius</FormLabel>
					<RadioGroup aria-label="radius" name="radius" value={ this.state.setting_radius } onChange={ this.handleRadiusChange }>
					  <FormControlLabel value="1000" control={<Radio style={{ paddinTop: 3, paddingBottom: 3 }} />} label="1 km (~1100 yd)" />
					  <FormControlLabel value="4000" control={<Radio style={{ paddinTop: 3, paddingBottom: 3 }} />} label="4 km (~2 mi)" />
					  <FormControlLabel value="6000" control={<Radio style={{ paddinTop: 3, paddingBottom: 3 }} />} label="6 km (~3.7 mi)" />
					</RadioGroup>
					</div>
					}
				</DialogContent>
				<DialogActions>
				  <Button onClick={this.handleSubmit} color="primary" type="submit">
					Continue
				  </Button>
				</DialogActions>
			</div>
			}
			</div>
		:
		<DialogContent>
			<DialogContentText>
			  <center><p>Establishing connection to server...</p></center>
			</DialogContentText>
		</DialogContent>
		}
      </Dialog>
	  </div>
  );
}

}

export default NameForm;
