import React, { Component } from "react";
//import socketIOClient from "socket.io-client";

//import ReactDOM from 'react-dom';
//import Button from '@material-ui/core/Button';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from '@material-ui/core/Typography';

//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Container from '@material-ui/core/Container';

//import Divider from '@material-ui/core/Divider';

//import ReactGA from 'react-ga';

//Change socket backend depending on environment.
if (process.env.NODE_ENV !== 'production') {
	//development
	//const socket = socketIOClient(process.env.NODE_ENV == 'production' ? 'https://noyoupick.one:4001' : 'https://noyoupick.one:4001');
} else {
	//production
	//ReactGA.initialize('UA-160617157-1');
	//ReactGA.pageview(window.location.pathname);
	//const socket = socketIOClient('https://noyoupick.one:4001');
}

class BrowserNotSupported extends Component {

  constructor(props) {
    super();

    this.state = {
      response: true,
    };

	//Detect user agent.
	var useragent = require('useragent');
	var agent = useragent.parse(navigator.userAgent);

  }

  done(id) {
      console.log('done');
  }

  copyLinkToClipboard() {
	  navigator.clipboard.writeText('https://noyoupick.one'+window.location.pathname)
	  //TODO add some user feedback here.
  }


  componentDidMount() {

  }

  render() {

	const theme = createMuiTheme({
	  palette: {
	    background: {
	      	default: this.props.backgroundColor
		  },
	  },
		typography: {
		  fontFamily: "Raleway, sans-serif",
		  fontWeight: '500',
		  h1: {
			  fontFamily: "Atma, cursive",
			  fontWeight: 700
		  },
		  h2: {
			  fontFamily: "Atma, cursive",
			  fontWeight: 700
		  },
		  p: {
			  fontWeight: '500',
		  }
		}
	});

    return (
		<MuiThemeProvider theme={theme}>
		<CssBaseline />
		<div style={{ height: '100vh', margin: 0, padding: 0 }} >
        <Container maxWidth="sm" height="100%">

		<Dialog disableBackdropClick disableEscapeKeyDown fullWidth={ true } maxWidth={ 'sm' } open={ true } aria-labelledby="form-dialog-title">
			  <DialogContent>
				  <DialogContentText>
				  <Typography>
					  <center>
					  <img style={{ width: 110 }} src="logo_200px.png"></img>
					  <Typography variant="h2" component="h2" style={{ fontSize: 28, fontWeight: 600, color: 'black', marginBottom: 20 }}>
					  No, You Pick!
					  </Typography>
					  </center>
					</Typography>
				  <Typography variant="p" style={{ fontSize: 18, fontWeight: 400}}>
					  <p><b>Whoops!</b> This in-app Facebook browser is not supported.</p>
					  <p>Please copy this link to your phone's browser instead.</p>
					  <TextField
						margin="dense"
						variant="outlined"
						id="roomlink"
						label="Link"
						type="text"
						fullWidth
						onFocus={event => {event.target.select()}}
						value={ 'https://noyoupick.one'+window.location.pathname }
						defaultValue={ 'https://noyoupick.one'+window.location.pathname }
						InputProps={{
							readOnly: true,
						}}
						autoComplete="off"
						style={{ marginBottom: 10, fontSize: 22 }}
					  />
				  </Typography>
				  </DialogContentText>
			  </DialogContent>
		  </Dialog>
		  </Container>
		</div>
		</MuiThemeProvider>

    );
  }
}

export default BrowserNotSupported;
