import React, { Component } from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';

class SimpleCard extends Component {

constructor(props) {
  super();
  this.state = {
	  image_index: 0
  };

  this.handleClick = this.handleClick.bind(this);

}

componentDidMount() {
	console.log('mounted');
	this.setState({
		//image: this.props.data.images[0]
	})
}

// Converts numeric degrees to radians
toRad(Value) {
  return Value * Math.PI / 180;
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
getDistance(lat1, lon1, lat2, lon2) {
	var R = 6371; // km
	var dLat = this.toRad(lat2-lat1);
	var dLon = this.toRad(lon2-lon1);
	lat1 = this.toRad(lat1);
	lat2 = this.toRad(lat2);

	var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	  Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;

	if (d < 1) {
		d = d * 1000;
		return d.toFixed(0) + ' m';
	} else {
		return d.toFixed(1) + ' km';
	}
}

handleClick(){
	var index = this.state.image_index;

	if (this.props.data.images.length > index+1) {
		index++;
	} else {
		index = 0;
	}

	this.setState({
		image_index: index
	});
}

render() {

	var half = '';
	if (!Number.isInteger(this.props.data.rating)){
		half = "_half";
	}

	var categories = [];
	for (var i = 0; i < this.props.data.categories.length; i++) {
		categories.push(<span>{this.props.data.categories[i].title}</span>);
		if (i + 1 < this.props.data.categories.length) {
			categories.push(<span>, </span>);
		}
	}

	return (
      <Card style={{ height: '60vh', minWidth: 275 }}>
  	  <CardMedia
	  	  onClick={ this.handleClick }
          style={{ height: '60%' }}
          image={ this.props.data.images[this.state.image_index] }
          title="image"
        />

    <CardContent style={{ height: '40%', overflowY: 'scroll' }}>
	  		<Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            About { this.getDistance(this.props.data.geometry.lat, this.props.data.geometry.lon, this.props.lat, this.props.lon ) } away
          </Typography>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            { this.props.data.name }
          </Typography>

            <Typography style={{ marginBottom: 12 }} color="textSecondary">
    			<img style={{ marginTop: 5 }}  src={ "https://noyoupick.one/assets/stars/regular_"+parseInt(this.props.data.rating)+half+".png"}></img>
            </Typography>

	  		{ this.props.data.price_level &&
	          <Typography style={{ marginBottom: 12, marginTop: 0 }} color="textSecondary">
	  			<p style={{marginBottom: 0, marginTop: 0}} style={{ fontWeight: 600 }}>
	  			{ this.props.data.price_level }
	  			</p>
	  		</Typography>
	  		}
			<Typography style={{ marginBottom: 12, marginTop: 0 }} color="textSecondary">
				{ categories }
			</Typography>
			<Typography align={ 'right' } style={{ marginBottom: 12, marginTop: 0 }} color="textSecondary">
			<a target="_blank" href={ this.props.data.url }><img src="https://noyoupick.one/assets/yelp.png"></img></a>
			</Typography>

        </CardContent>
      </Card>
    );
}

}

export default SimpleCard
