import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import BrowserNotSupported from './BrowserNotSupported';
import * as serviceWorker from './serviceWorker';

var rooms = [
	'tuna',
	'squid',
	'shrimp',
	'ham',
	'sausage',
	'cucumber',
	'cabbage',
	'onions',
	'strawberry',
	'pineapple',
	'blueberry',
	'lemon',
	'blackberry',
	'salmon',
	'apple',
	'egg',
	'gelato',
	'popsicle',
	'fondue',
	'chocolate',
	'lobster',
	'rice',
	'wheat',
	'eggtart',
	'ketchup'
]

if (process.env.NODE_ENV !== 'production') {
	//development
	var colors = [
		'aaa'
	];
} else {
	//production
	var colors = [
		'BEEBE9',
		'79BAC1',
		'E5C3D1',
		'FFB7B2',
		'FF9AA2',
		'FFDAC1',
		'E2F0CB',
		'B5EAD7',
		'AEC6CF',
		'E3BC9A',
		'FFFDD0',
		'C7CEEA'
	];
}

//Detect user agent.
var useragent = require('useragent');
var agent = useragent.parse(navigator.userAgent);

if (agent.family.toUpperCase().includes('FACEBOOK')) {

	ReactDOM.render(<BrowserNotSupported />, document.getElementById('root'));

} else {
	//First check if URL is empty
	console.log('room specified', window.location.pathname);
	if (window.location.pathname === '/') {
		window.location.href = rooms[Math.floor(Math.random() * rooms.length)] + document.location.search;

	} else {
		ReactDOM.render(<App backgroundColor={ '#'+colors[Math.floor(Math.random() * colors.length)] }/>, document.getElementById('root'));

	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
