import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class ErrorDialog extends React.Component {

constructor(props) {
    super(props);
    this.state = {
		name: null,
		open: true
	};

	this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

handleChange(event) {
  this.setState({name: event.target.value});
}

handleClose(event) {
	this.setState({open: false});
}

handleSubmit(event) {
	this.setState({open: false});
}


render() {
  return (
    <div>
      <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: this.props.message }} />
			{ this.props.button &&
				<center><Button variant="contained" color="secondary" href={ this.props.buttonLink }>{ this.props.buttonText }</Button></center>
			}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

}

export default ErrorDialog;
